export const updateCanonicalTag = (url) => {
    if (!url) {
      const existingTag = document.querySelector('link[rel="canonical"]');
      if (existingTag) {
        url = existingTag.href;
      } else {
        console.error("updateCanonicalTag: URL is undefined and no canonical tag found");
        return;
      }
    }
  
    console.log("Original URL:", url);
    const updatedURL = url.replace("docs-aws.niyogin.com", "www.niyogin.com");
    console.log("Updated Canonical URL:", updatedURL);
    document.querySelectorAll('link[rel="canonical"]').forEach(tag => tag.remove());
  
    const canonicalTag = document.createElement("link");
    canonicalTag.setAttribute("rel", "canonical");
    canonicalTag.setAttribute("href", updatedURL);
    document.head.appendChild(canonicalTag);
  
    console.log("Final Canonical URL in DOM:", document.querySelector('link[rel="canonical"]').href);
  };