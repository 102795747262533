import React, { useEffect, useState } from 'react'
import NIYOGIN_FEED_img from "../assets/images/Group_NIYOGIN.png"
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { PRODUCTION_WORDPRESS_DOMAIN } from '../utils/domain';
export default function NIYOGINfeed() {
    const [blogData, setBlogsData] = useState(null)
    
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay:true,
        arrows:true,
        responsive: [
            {
              breakpoint: 1150,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: false,
              },
            },
            {
                breakpoint: 1050,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                },
              },
              {
                breakpoint: 750,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                },
              },
        ]
      };

      const BlogData = async () => {
        const response = await fetch(`${PRODUCTION_WORDPRESS_DOMAIN}/wp-json/wp/v2/posts`)
        const text = await response.text();
        // console.log("text", text);
        
        if (text.trim().startsWith('{') || text.trim().startsWith('[')){
          setBlogsData(JSON.parse(text));
        } else {
          const cleanedText = text.replace(/<[^>]*>/g, "");
          const jsonStartIndex = cleanedText.indexOf('[');
          const jsonString = cleanedText.slice(jsonStartIndex).trim();
          const data = JSON.parse(jsonString);
          setBlogsData(data);
        }
      }
      useEffect(() => {
          BlogData() 
        // fetch("https://docs.niyogin.com/wp-json/wp/v2/posts")
        // .then((response) => response?.json())
        // .then((data) => {
        //     console?.log("blogs =====>> ",data)
        //     // setBlogsData(data)
        // });
      }, [])
      
  return (
    <div className='NIYOGINfeed_main_cont'>
        <div className="NIYOGINfeed_imag_and_slider_div">
            <img src={NIYOGIN_FEED_img} alt='NIYOGIN FEED'/>
            <div className='block_slider_feed'  data-aos="fade-down" 
     data-aos-easing="ease-in-sine" data-aos-duration="1000">
                <Slider {...settings}>

                    {
                         blogData && blogData.map((blog) => (
                          <div className='block_feed_div' >
                                <a href='#'>
                                    <h3 className='feed_tit'>
                                        {String(blog?.title?.rendered).replaceAll("&#8211;", "-")}
                                    </h3>
                                    <p className='feed_paragaph' 
                                    dangerouslySetInnerHTML={{
                                      __html: blog?.excerpt?.rendered?.length>100?blog?.excerpt?.rendered?.slice(0, 100) + '...':blog?.excerpt?.rendered|| "No description available",
                                      }}
                                      >
                                       {/* {blog.excerpt.rendered.length>100?blog.excerpt.rendered.slice(0, 100) + '...':""} */}
                                    </p>
                                    <div className='read_center'>
                                        <p className='feed_btn a' >
                                          <a href={`/blogs/`+ blog?.slug}>
                                          Read More 
                                          {/*  - {blog.id} */}
                                          </a>
                                        </p>
                                    </div>
                                </a>
                            </div>
                        ))
                    }
                   
                
                    
                
                </Slider>
            </div>
        </div>
    </div>
  )
}
