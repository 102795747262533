import { Box, Tab, Tabs } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types';
import anime from 'animejs';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Arrow_investor from "../assets/images/Arrow_Vector.png"
import DowloadIcon from "../assets/images/downloadIcon.png"
import { tab } from '@testing-library/user-event/dist/tab';
// import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { PRODUCTION_WORDPRESS_DOMAIN } from '../utils/domain';


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      className='dat_tabs'
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    //   'aria-selected':"true",
    //   'class':'MuiTab-textColorPrimary'

    //   class:`Tab_button_class`

  };
}

function CustomTabPanel2(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role={`tabpanel_level ` + `${index}`}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      className='MiniTabContent'
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel2.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,

};

function a11yProps2(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
    //   'aria-selected':"true",
    //   'class':'MuiTab-textColorPrimary'

    //   class:`Tab_button_class`

  };
}


export default function InvestorRetailsTabs() {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [LoadingData, setLoadingData] = useState(null);
  const [NewData, setNewData] = useState(null)
  const ImageArr = <>
    <img className='arr_down_tab' src={Arrow_investor} alt="" />
  </>
  let count = 0
  let count2 = -1
  // const [count, setCount] = useState(0)
  let mainLoop = 0
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange2 = (event, newValue1) => {
    console.log("on chnage ", "new value ", newValue1, "value2 ", value2, "  evnet ------  ",
      event?.target?.id?.split("-")[1])
    // setValue2(15);
    setValue2(Number(event?.target?.id?.split("-")[1]) - 1);

  };
  const svgRef2 = useRef(null);
  // const svgRef2 = useRef(null);
  useEffect(() => {
    // const svg1 = svgRef1.current;
    const svg2 = svgRef2.current;

    // const paths1 = svgRef1.current.querySelectorAll('path');
    const paths2 = svgRef2.current.querySelectorAll('path');
    fetch(`${PRODUCTION_WORDPRESS_DOMAIN}/wp-json/wp/v2/investor-documents?per_page=100&page=1`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); // Parse the JSON data
      })
      .then(async (text) => {
          if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
            // setLoadingData(JSON.parse(text));  
            console.log(JSON.parse(text),"JSON PARSE")
            setNewData(JSON.parse(text))
          } else {
          const cleanedText = text.replace(/<[^>]*>/g, '')
          const jsonStartIndex = cleanedText.indexOf('[');
          const jsonString = cleanedText.slice(jsonStartIndex).trim();
          const newdata = await JSON.parse(jsonString);
          console.log("newdata", newdata)
          setNewData(newdata)
          }
        // console.log("newdata", JSON.parse( await JSON.stringify(String(newdata))))
        // }
  //       if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
  //         // Parse directly
  //         const parsedData = JSON.parse(text.trim());
  //         console.log("Parsed JSON:", parsedData);
  //     } else {
  //         // Clean the text to find the JSON part
  //         const cleanedText = text.replace(/<[^>]*>/g, ''); // Remove HTML tags if present
  //         const jsonStartIndex = cleanedText.indexOf('{'); // Find where JSON starts
  //         const jsonString = cleanedText.slice(jsonStartIndex).trim(); // Extract JSON string
  // console.log("jsonString", jsonString);
  
  //         // Parse the extracted JSON string
  //         const parsedData = JSON.parse(jsonString);
  //         console.log("Parsed JSON from cleaned text:", parsedData);
  //     }
        // data.map((data,ind)=>{

        //   console.log("dat ---- ",ind," slug --- ",data.slug ," ---- ",data.acf.accordion_details.title)
        // })
        // setData(data[0]?.acf?.investor_services?.content); // Set the data
        // setLoading(false); // Stop loading once data is fetched
        // console.log("data investor-documents --------- > ",data[5].title)
        // setLoadingData(data[0].acf.business_Volumes)

      })
      .catch((error) => {
        console.log("error", error);
        
        // setError(error); // Handle any errors
        // setLoading(false);
      });

    fetch(`${PRODUCTION_WORDPRESS_DOMAIN}/wp-json/wp/v2/investor-docs-categories`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text(); 
      })
      .then(async (text) => {
        console.log("text",text);
        if (text.trim().startsWith('{') || text.trim().startsWith('[')) {
          console.log("IF",JSON.parse(text).categories);
          setLoadingData(await JSON.parse(text).categories);  
        } else {
        const cleanedText = text.replace(/<[^>]*>/g, '')
        const jsonStartIndex = cleanedText.indexOf('{');
        const jsonString = cleanedText.slice(jsonStartIndex).trim();
        const data = JSON.parse(jsonString);
        setLoadingData(data.categories)
        }
        // setData(data[0]?.acf?.investor_services?.content); // Set the data
        // setLoading(false); // Stop loading once data is fetched
        // console.log("data ===== >>>>>> ",data.categories)
        // setLoadingData(data[0].acf.business_Volumes)

      })
      .catch((error) => {
        // setError(error); // Handle any errors
        // setLoading(false);
        console.log("error fetching categories:", error);
      });


    anime({
      targets: paths2,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeInOutSine',
      duration: 5000,
      direction: 'normal'
      // loop: true,

    });

  }, [])

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));


  const [expanded, setExpanded] = React.useState('panel');

  const handleChangeaccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    setValue2(0);
  };
  
  const renderSchemeTabs = (accordian, categoryId, index, INd) => {
    let tabIndex = 0;
    let renderedTabs = [];

    if (!NewData) return null;

    NewData.forEach((Tabdata, ind) => {
      if (Tabdata?.categories.includes(categoryId) &&
          Tabdata?.acf?.accordion_details?.files?.length) {
        renderedTabs.push(
          <Tab 
            onClick={(e) => handleChange2(e)} 
            key={`scheme1-${tabIndex}`} 
            label={Tabdata?.acf?.accordion_details?.title || Tabdata?.title?.rendered} 
            {...a11yProps2(tabIndex + 1)} 
          />
        );
        tabIndex++;
      }
    });

    NewData.forEach((Tabdata, ind) => {
      if (Tabdata?.categories.includes(categoryId) &&
          Tabdata?.acf?.accordion_details_scheme_2?.files?.length) {
        renderedTabs.push(
          <Tab 
            onClick={(e) => handleChange2(e)} 
            key={`scheme2-${tabIndex}`} 
            label={Tabdata?.acf?.accordion_details_scheme_2?.title || "Scheme 2"} 
            {...a11yProps2(tabIndex + 1)} 
          />
        );
        tabIndex++;
      }
    });

    return renderedTabs;
  };

  const renderSchemeContent = (accordian, categoryId) => {
    let tabIndex = -1;
    let renderedContent = [];

    if (!NewData) return null;

    NewData.forEach((Tabdata, ind) => {
      if (Tabdata?.categories.includes(categoryId) &&
          Tabdata?.acf?.accordion_details?.files?.length) {
        tabIndex++;
        renderedContent.push(
          <CustomTabPanel2 value={value2} index={tabIndex} key={`content-scheme1-${tabIndex}`}>
            <div className='tab_mini_content'>
              {Tabdata?.acf?.accordion_details?.files.map((data_url, textNub) => (
                <div 
                  className='dowlod_block' 
                  key={textNub} 
                  onClick={() => { window.location.href = data_url?.file; }}
                >
                  <p>{data_url?.name}</p>
                  <div className='imgdownload'>
                    <img src={DowloadIcon} alt="Download Icon" />
                  </div>
                </div>
              ))}
            </div>
          </CustomTabPanel2>
        );
      }
    });

    NewData.forEach((Tabdata, ind) => {
      if (Tabdata?.categories.includes(categoryId) &&
          Tabdata?.acf?.accordion_details_scheme_2?.files?.length) {
        tabIndex++;
        renderedContent.push(
          <CustomTabPanel2 value={value2} index={tabIndex} key={`content-scheme2-${tabIndex}`}>
            <div className='tab_mini_content'>
              {Tabdata?.acf?.accordion_details_scheme_2?.files.map((data_url, textNub) => (
                <div 
                  className='dowlod_block' 
                  key={textNub} 
                  onClick={() => { window.location.href = data_url?.file; }}
                >
                  <p>{data_url?.name}</p>
                  <div className='imgdownload'>
                    <img src={DowloadIcon} alt="Download Icon" />
                  </div>
                </div>
              ))}
            </div>
          </CustomTabPanel2>
        );
      }
    });

    return renderedContent;
  };
  
  return (<>
    <div className='svg_animation1 new_svg'>
      <svg width="100%" ref={svgRef2} id="my_svg_element" height="100%" viewBox="0 0 1425 885" fill="none" xmlns="http://www.w3.org/2000/svg" class=" drawsvg-initialized " >
        <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="1425" height="885">
          <rect width="1425" height="885" fill="#C4C4C4"></rect>
        </mask>
        <g mask="url(#mask0)">
          <path d="M1234.72 1197.88C1234.72 1197.88 1252.8 647.228 685.93 636.622C607.34 635.135 58.4301 618.807 -395.928 605.028" stroke="#24A0D0" stroke-opacity="0.2" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1276.39 1137.72C1276.39 1137.72 1249.57 635.389 704.334 625.201C628.193 623.771 65.1712 624.567 -396.711 625.544" stroke="#24A0D0" stroke-opacity="0.3" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1317.95 1077.56C1317.95 1077.56 1246.12 623.458 722.766 613.651C649.074 612.278 71.9185 630.757 -397.627 646.509" stroke="#24A0D0" stroke-opacity="0.4" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1359.63 1017.41C1359.63 1017.41 1242.89 611.64 741.308 602.232C670.181 600.919 78.7543 637.66 -398.342 668.275" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1401.31 957.252C1401.31 957.252 1239.55 599.711 759.832 590.684C691.27 589.431 85.4117 644.99 -399.194 690.599" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1442.87 897.093C1442.87 897.093 1236.24 587.762 778.259 579.263C712.376 578.072 91.8623 652.897 -400.077 713.74" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1484.55 836.937C1484.55 836.937 1232.88 575.941 796.806 567.716C733.604 566.587 98.392 661.151 -400.973 737.205" stroke="#24A0D0" stroke-opacity="0.5" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1526.11 776.779C1526.11 776.779 1229.56 564.013 815.21 556.294C754.83 555.124 104.784 669.983 -401.9 761.488" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1567.79 716.602C1567.79 716.602 1226.22 552.171 833.753 544.855C776.192 543.75 111.156 679.353 -402.843 786.201" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1609.46 656.447C1609.46 656.447 1223 540.224 852.301 533.307C797.419 532.265 117.371 689.194 -403.808 811.496" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1651.03 596.288C1651.03 596.288 1219.55 528.293 870.71 521.756C818.763 520.783 123.453 699.485 -404.791 837.242" stroke="#24A0D0" stroke-opacity="0.7" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1692.7 536.133C1692.7 536.133 1216.32 516.475 889.137 510.335C840.239 509.433 129.541 710.227 -405.8 863.7" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1734.38 475.977C1734.38 475.977 1212.99 504.546 907.657 498.895C861.694 498.06 135.584 721.55 -406.845 890.457" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1775.94 415.818C1775.94 415.818 1209.64 492.726 926.087 487.366C883.175 486.581 141.387 733.084 -407.862 917.711" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1817.62 355.663C1817.62 355.663 1206.31 480.776 944.631 475.926C904.79 475.235 147.284 745.201 -408.927 945.61" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1859.18 295.504C1859.18 295.504 1202.97 468.955 963.039 464.397C926.368 463.65 152.909 757.764 -410.002 973.768" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1900.86 235.349C1900.86 235.349 1199.63 457.027 981.581 452.957C947.961 452.281 158.66 770.675 -411.094 1002.38" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1942.42 175.169C1942.42 175.169 1196.29 445.056 1000.01 441.407C969.697 440.808 164.251 784.143 -412.207 1031.55" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1984.1 115.013C1984.1 115.013 1192.95 433.235 1018.56 429.967C991.404 429.464 169.737 797.953 -413.333 1061.06" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1978.63 75.3254C1799.92 152.368 1167.73 420.925 1037.08 418.44C1013.25 417.992 175.184 812.127 -414.358 1090.9" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1859.79 90.4618C1590.81 212.846 1145.58 408.667 1055.51 407.02C1034.96 406.649 180.496 826.86 -415.63 1121.23" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1737.49 115.88C1466.63 245.395 1136.37 396.688 1074.03 395.472C1056.79 395.178 185.661 841.783 -416.822 1151.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1635.94 138.959C1388.04 263.407 1135.12 384.679 1092.46 383.922C1078.64 383.707 190.947 857.183 -417.982 1182.86" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1556.11 157.395C1336.8 272.943 1138.62 373.018 1111.01 372.503C1100.61 372.261 196.1 873.053 -419.072 1214.45" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1494.67 171.24C1303.37 277.055 1145.68 361.31 1129.43 361.061C1122.48 360.899 201.004 889.37 -420.377 1246.22" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
          <path d="M1447.67 181.09C1281.68 277.584 1155.17 349.681 1147.95 349.621C1144.42 349.538 205.965 906.012 -421.51 1278.34" stroke="#24A0D0" stroke-width="0.7937" stroke-miterlimit="10" ></path>
        </g>
      </svg>
    </div>
    <div className='InvestorRetailsTabs_main_container'>
      <div className='' data-aos="fade-up" data-aos-offset="100"
        data-aos-easing="ease-in-sine" data-aos-duration="1000"  >
        {LoadingData && NewData && LoadingData.length > 0 && NewData.length > 0 ?
        // {LoadingData && NewData && LoadingData.length > 0 && NewData.length > 0 ?
          (<>
            <Box sx={{ width: '100%' }} >
              <Box sx={{ paddingBottom: '50px', maxWidth: "1350px" }}  >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className='tab_class_conta'
                >
                  {LoadingData && LoadingData.length > 0 ? (
                    LoadingData.map((data, index) => (
                      <Tab
                        key={index}
                        label={
                          <div className='tab_container' data-index={index}>
                            <p>{data?.name}</p>
                          </div>
                        }
                        {...a11yProps(index)}
                      />
                    ))
                  ) : (
                    <p className='loader'></p>
                  )}
                </Tabs>


              </Box>


              {LoadingData && LoadingData.length > 0 ? (
                LoadingData.map((data, index) => {
                  // console.log("Business Title: ", data);
                  return (<>
                    <CustomTabPanel value={value} index={index}>
                      <div className='InvestorNewTabs_cont'>


                        <div className='p_tabs_'>
                          <h2>{data?.cat_name}</h2>
                          <div dangerouslySetInnerHTML={{ __html: data?.detailed_description }}></div>
                          {/* {data?.detailed_description} */}
                        </div>

                        <div className='acord_cont'>

                          {data?.tabs?.map((accordian, INd) => {
                            return (<>
                              <div key={`accordion-${index}-${INd}`}>
                                <Accordion className='acordian_ivest' expanded={expanded === 'panel' + (index + INd)} onChange={handleChangeaccordion(`panel` + (index + INd))}>
                                  <AccordionSummary className='acordian_main_invest'
                                    expandIcon={ImageArr}
                                    aria-controls="panel1-content"
                                    id="panel1-header"
                                  >
                                    <p>
                                      {accordian?.name }
                                    </p>
                                  </AccordionSummary>
                                  <AccordionDetails className='MiniMoreTaB'>
                                    <Box sx={{ width: '100%', zIndex: '6', position: "relative" }} >
                                      <Box sx={{}} >
                                        <Tabs variant="scrollable"
                                          scrollButtons="auto"
                                          aria-label="scrollable auto tabs example" 
                                          className='mini_tabs'
                                          value={value2} 
                                        >
                                          {renderSchemeTabs(accordian, accordian?.cat_ID, index, INd)}
                                        </Tabs>
                                      </Box>
                                      
                                      {renderSchemeContent(accordian, accordian?.cat_ID)}
                                    </Box>

                                  </AccordionDetails>
                                </Accordion>
                              </div>
                            </>)
                          })}
                        </div>


                      </div>
                    </CustomTabPanel>
                  </>
                  );
                })
              ) : (
                <p></p>
              )}



            </Box>
          </>) :
          (<>
            <p className="loader"></p>
          </>)}

      </div>
    </div>
  </>

  )
}
